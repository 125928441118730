.datepicker__input {
    height: 35px;
    width: 100%;
}

.btn-center {
    margin: auto !important;
    max-width: 300px;   
}

.btn-submit {
    display: block;
    width: 100%;
}

@import "../../node_modules/react-select/less/select.less";
 
@import "react-datepicker.css";
@import "normalize.css";
@import "webflow.css";
@import "sunex.webflow.css";